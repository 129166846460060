import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Login,
  Launch,
  Recovery,
  ViewItem,
  Download,
  AllProducts,
  DollarCourse,
  RecoveryChart,
  CreateProducts,
  ViewRecoveryItem,
} from "../screens";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Launch />} />
        <Route path='/login' element={<Login />} />
        <Route path='/recovery' element={<Recovery />} />
        <Route path='/download' element={<Download />} />
        <Route path='/viewItem' element={<ViewItem />} />
        <Route path='/allProducts' element={<AllProducts />} />
        <Route path='/dollarCourse' element={<DollarCourse />} />
        <Route path='/recoveryChart' element={<RecoveryChart />} />
        <Route path='/createProducts' element={<CreateProducts />} />
        <Route path='/viewRecoveryItem' element={<ViewRecoveryItem />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
