import React, { useEffect, useRef, useState } from "react";
import { ErrorModal } from "../../components/Modal";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import DownloadStyle from "./DownloadStyle";
import writtenNumber from "written-number";
import DollarToWord from "./DollarToWord";
import html2PDF from "jspdf-html2canvas";
import { get } from "lodash";
import api from "../../api";

const Download = () => {
  const location = useLocation();
  const errorRef = useRef();

  const dataLocation = get(location, "state.data", {});
  const [userData, setuserData] = useState({});
  const [dollar, setDollar] = useState({});
  const data = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  useEffect(() => {
    getUser();
    getUSD();
  }, []);

  const getUser = () => {
    api
      .get(
        `BusinessPartners('${get(
          dataLocation,
          "CardCode",
          0
        )}')?$select=CardCode,CardName,Address,Phone1,U_Gender,U_PS,BPAddresses`
      )
      .then((res) => {
        const resData = JSON.parse(res.data);
        setuserData(resData);
      })
      .catch((err) => {
        console.log("err", err);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  const getUSD = () => {
    api
      .get(
        `SQLQueries('getUSDRate')/List?date='${get(
          dataLocation,
          "DocDate",
          ""
        )}T00:00:00Z'`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setDollar(get(resData, "[0].Rate", 0));
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  const download = () => {
    let element = document.getElementsByClassName("container");

    html2PDF(element, {
      margin: 10,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    });
  };

  return (
    <Layout>
      <DownloadStyle>
        <div className='flexEnd'>
          <Button
            onClick={download}
            btnStyle={{ marginBottom: 10, width: 200 }}
          >
            Загрузить договор
          </Button>
        </div>
        <div className='container'>
          <div className='halfCard'>
            <div className='halfCardMini'>
              <p className='topTitle'>
                Муддатли тўлов асосида сотиш-харид қилиш шартномаси №{" "}
                {get(dataLocation, "DocEntry", 0)}
              </p>
              <div className='between'>
                <p>Тошкент ш.</p>
                <p>
                  {get(dataLocation, "DocDate", "").slice(-2)}
                  {"-"}
                  {get(dataLocation, "DocDate", "").slice(-5, -3)}-
                  {get(dataLocation, "DocDate", "").slice(0, 4)} й.
                </p>
              </div>
              <p>
                <b>"ZAFAR TRAVEL CO" МЧЖ</b> кейинги ўринларда <b>"Сотувчи"</b>{" "}
                деб аталувчи, Устав асосида ҳаракат қилувчи директор{" "}
                <b>Нигматов О.Х.</b> юзасида, бир томондан, ва
              </p>
              <p> {get(dataLocation, "CardName", "")} (ФИШ), </p>
              <p>паспорт/id серия ва рақами {get(userData, "U_PS", "")} </p>

              <div className='flex'>
                <input type='text' placeholder='Passport berilgan manzil' />
                <p>томонидан, </p>
              </div>
              <div className='flex'>
                <input type='date' placeholder='Passport berilgan sana' />

                <p>(паспорт берилган сана) </p>
              </div>
              <p>
                да берилган:{get(userData, "BPAddresses[0].AddressName", "")},
                {get(userData, "BPAddresses[0].Street", "")} {"  "}(яшаш
                манзили) манзилда истиқомад қилувчи, Ўзбекистон Республикаси
                фуқароси кейинги ўринларда <b>"Харидор"</b> деб аталувчи бошқа
                томондан, ушбу шартномани қуйидагилар ҳақида туздилар:
              </p>

              {/* first */}
              <>
                <p className='topTitle'>1.ШАРТНОМА ПРЕДМЕТИ</p>
                <p>
                  1.1. Сотувчи Товар ва унинг тўпламига кирувчи аксессуарларни
                  ва хужжатлари Харидорга фойдаланиши учун топшириш, Харидор эса
                  Товар қийматини ушбу Шартномада кўрсатилган шартларда ва
                  миқдорда тўлаш мажбуриятини олади.
                </p>
                <p>
                  1.2. Сотилган товар хақида маълумот (кейинги ўринларда{" "}
                  <b>“Товар”</b> деб юритилади):{" "}
                  {get(dataLocation, "DocumentLines[0].ItemDescription", "")}
                </p>
                <div className='flex'>
                  <p>IMEI 1 код: </p>
                  <p>
                    {get(
                      dataLocation,
                      "DocumentLines[0].SerialNumbers[0].InternalSerialNumber",
                      0
                    )}
                  </p>
                </div>
                <div className='flex'>
                  <p>IMEI 2 код:</p>
                  <input type='number' name='imei' id='imei' />
                </div>
              </>
              <br />

              {/* second */}
              <>
                <p className='topTitle'>2.ШАРТНОМА ҚИЙМАТИ ВА ТЎЛОВ ТАРТИБИ</p>
                <p>
                  2.1. Товарнинг умумий қиймати{" "}
                  <b>
                    {DollarToWord(get(dataLocation, "DocTotal", 0) * dollar)}
                  </b>{" "}
                  сўмни ташкил қилади.
                </p>
                <p>
                  2.2. Харидор Товар учун олдиндан{" "}
                  <b>
                    {DollarToWord(
                      get(dataLocation, "DocumentInstallments[0].Total", 0) *
                        dollar
                    )}
                  </b>{" "}
                  сўм тўловни амалга оширади.
                </p>
                <p>
                  2.3. Харидор Товарнинг қолган{" "}
                  <b>
                    {" "}
                    {DollarToWord(
                      (get(dataLocation, "DocTotal", 0) -
                        get(dataLocation, "DocumentInstallments[0].Total", 0)) *
                        dollar
                    )}
                  </b>{" "}
                  сўм қийматини
                </p>
                <p>
                  {get(dataLocation, "DocumentInstallments", 1).length - 1} ой
                  давомида Тўлов жадвали (1-Илова) буйича тўлов қилишга мажбур.
                </p>
                <p>
                  2.4. Товар учун ойлик тўлов муддати уни сотиб олинган кунига
                  белгиланади. Агар Товар жорий ойнинг 25-кунидан кейин сотиб
                  олинган бўлса, сотиб олинган санадан қатъи назар, тўлов
                  муддати ҳар ойнинг 25-кунида амалга оширилади.
                </p>
              </>

              {/* thrid */}
              <>
                <p className='topTitle'>3.ТОВАРНИ ТОПШИРИШ ТАРТИБИ</p>
                <p>
                  3.1. Товарни топшириш қуйидаги манзил бўйича амалга оширилади:
                  г.Тошкент шаҳри, Олмазор тумани, Нурафшон айланма кўчаси, 1
                  уй, 12 хонадон.
                </p>
                <p>
                  3.2. Ушбу Шартноманинг 2.2.- бандида кўрсатилган олдиндан
                  тўлов амалга оширишдан олдин Харидор Товарнинг тўлиқлиги ва
                  яроқлигини текширишга мажбур.
                </p>
                <p>
                  3.3. Олдиндан тўловни амалга ошириши орқали Харидор ўзи харид
                  қилган Товарни танлагани текшириб кўрганлиги ва қабул қилишга
                  тайёрлигини тасдиқлайди.
                </p>
                <p>
                  3.4. Сотувчи ушбу Шартнома бўйича Товарни харидорга олдиндан
                  тўловини амалга оширган пайтдан бошлаб 1 (бир) календар кун
                  давомида топширади.
                </p>
                <p>
                  3.5. Товар тегишли хужжатлар билан топширади. Товарнинг қутиси
                  ушбу Шартноманинг 2.1.-бандда кўрсатилган тўлиқ қиймати
                  тўланганидан кейин Харидорга топширади.
                </p>
              </>
            </div>
          </div>

          {/* right */}
          <div className='halfCard'>
            <div className='halfCardMini'>
              <p className='topTitle'>
                Договор купли-продажи на основе рассрочки №{" "}
                {get(dataLocation, "DocEntry", 0)}
              </p>
              <div className='between'>
                <p>г. Ташкент</p>
                <p>
                  {get(dataLocation, "DocDate", "").slice(-2)}
                  {"-"}
                  {get(dataLocation, "DocDate", "").slice(-5, -3)}-
                  {get(dataLocation, "DocDate", "").slice(0, 4)} г.
                </p>
              </div>
              <p>
                <b> ООО "ZAFAR TRAVEL CO"</b>, далее именуемое <b>“Продавец”</b>
                , в лице директора <b>Нигматов О.Х.</b>, действующего на
                основании Устава, с одной стороны, и гражданин Республики
                Узбекистан
              </p>
              <p>{get(dataLocation, "CardName", "")} (ФИО),</p>
              <p>серия и номер паспорта : {get(userData, "U_PS", "")} выдан</p>
              <div className='flex'>
                <input type='text' placeholder='Адрес выдачи паспорта' />
                <p>от</p>
              </div>
              <div className='flex'>
                <input type='date' />
                <p>(дата выдачи),</p>
              </div>
              <p>
                проживающий по адресу:{" "}
                {get(userData, "BPAddresses[0].AddressName", "")},
                {get(userData, "BPAddresses[0].Street", "")},
              </p>
              <p>
                далее именуемый <b>“Покупатель”</b>, с другой стороны, заключили
                настоящий Договор о нижеследующем:
              </p>
              <br />

              {/* first */}
              <>
                <p className='topTitle'>1.ПРЕДМЕТ ДОГОВОРА</p>
                <p>
                  1.1. Продавец обязуется передать Покупателю Товар и входящие в
                  его состав аксессуары и документы, а Покупатель, в свою
                  очередь, обязуется оплатить стоимость Товара на условиях
                  настоящего Договора.
                </p>
                <p>
                  1.2. Информация о продаваемом Товаре (далее по тексту
                  <b>“Товар”</b> ):{" "}
                  {get(dataLocation, "DocumentLines[0].ItemDescription", "")}
                </p>
                <br />

                <div className='flex'>
                  <p>IMEI 1 код:</p>
                  <p>
                    {get(
                      dataLocation,
                      "DocumentLines[0].SerialNumbers[0].InternalSerialNumber",
                      0
                    )}
                  </p>
                </div>

                <div className='flex'>
                  <p>IMEI 2 код:</p>
                  <input type='number' name='imei' id='imei' />
                </div>
                <br />
              </>

              {/* second */}
              <>
                <p className='topTitle'>
                  2.СТОИМОСТЬ ДОГОВОРА И ПОРЯДОК ОПЛАТЫ
                </p>
                <p>
                  2.1. Общая стоимость Товара{" "}
                  <b>
                    {writtenNumber(get(dataLocation, "DocTotal", 1) * dollar, {
                      lang: "ru",
                    })}{" "}
                  </b>
                  сум.
                </p>

                <p>
                  2.2. Предоплата за Товар{" "}
                  <b>
                    {writtenNumber(
                      get(dataLocation, "DocumentInstallments[0].Total", 0) *
                        dollar,
                      {
                        lang: "ru",
                      }
                    )}
                  </b>{" "}
                  сум.
                </p>
                <p>
                  2.3. Оставшуюся стоимость Товара{" "}
                  <b>
                    {writtenNumber(
                      (get(dataLocation, "DocTotal", 0) -
                        get(dataLocation, "DocumentInstallments[0].Total", 1)) *
                        dollar,
                      {
                        lang: "ru",
                      }
                    )}
                  </b>{" "}
                  сум Покупатель обязуется выплатить в течение{" "}
                  {get(dataLocation, "DocumentInstallments", 1).length - 1}{" "}
                  месяцев согласно Графику платежей (Приложение 1).
                </p>

                <p>
                  2.4. Срок ежемесячной оплаты Товара устанавливается на день
                  его приобретения. Если Товар был приобретен после 25-го числа
                  текущего месяца, независимо от даты покупки, срок оплаты
                  устанавливается на 25-е число каждого месяца.
                </p>
              </>
              <br />

              {/* thrid */}

              <>
                <p className='topTitle'>3.ТОВАРНИ ТОПШИРИШ ТАРТИБИ</p>
                <p>
                  3.1. Передача Товара осуществляется по следующему адресу: г.
                  Ташкент, Алмазарский район, ул. Нурафшон, 1-дом, 12-квартира.
                </p>
                <p>
                  3.2. Покупатель перед оплатой предоплаты, предусмотренной
                  п.2.2 настоящего Договора, обязуется проверить Товар на
                  предмет комплектности и пригодности.
                </p>
                <p>
                  3.3. Осуществляя предоплату, Покупатель подтверждает, что
                  проверил выбранный Товар и готов его принять.
                </p>
                <p>
                  3.4. Продавец передает Покупателю Товар в течение 1 (одного)
                  календарного дня с момента осуществления предоплаты.
                </p>
                <p>
                  3.5. Товар передается вместе с надлежащими документами.
                  Коробка передается Покупателю после уплаты полной стоимости,
                  указанной в п.2.1. настоящего Договора.
                </p>
              </>
            </div>
          </div>
        </div>

        {/* secend big Card */}
        <div className='container'>
          <div className='halfCard'>
            <div className='halfCardMini'>
              <p>
                3.6. Олдиндан тўлов амага оширгандан сўнг, Харидор томонидан
                Товарни қабул қилишдан турли сабабларга кўра рад этилган
                тақдирда, тўланган олдиндан тўлов қайтарилмайди.
              </p>
              <p>
                3.7. Товарга эгалик хуқуқи ушбу Шартноманинг 2.1-бандида
                кўрсатилган Товарнинг тўлиқ қиймати тўланган пайтдан бошлаб
                Харидорга ўтади.
              </p>
              {/* fourth */}
              <p className='topTitle'>4.ТОМОНЛАРНИНГ ҲУҚУҚ ВА МАЖБУРИЯТЛАРИ</p>
              <p>
                4.1. <b>Сотувчининг мажбуриятлари:</b>
              </p>
              <p>
                4.1.1. Товарни сотиш вақтида Ўзбекистон Республикасининг қонун
                ҳужжатлари томонидан белгиланган талабларга жавоб берадиган
                маҳсулот ҳақидаги зарур ва ишончли маълумотларни ҳаридорга
                тақдим этиш.
              </p>
              <p>
                4.1.2. Ушбу Шартноманинг 3.4-бандида кўрсатилган муддатда
                Товарни топшириш.
              </p>
              <p>4.1.3. Тегишли сифатдаги Товарни топшириш. </p>
              <p>
                4.2. <b>Сотувчининг хуқуқлари:</b>
              </p>
              <p>
                4.2.1. Ушбу Шартноманинг шартларига мувофиқ Товар учун ўз
                вақтида тўловни талаб қилиш.
              </p>
              <p>
                4.2.2. Ушбу Шартноманинг тўлов шартлари бузилган тақдирда,
                Харидордан Товарни қайтариб олиш. Шу билан бирга, Харидор
                томонидан олдин тўланган пул миқдори қайтарилмайди ва
                Сотувчининг йўқотишлари қоплаш учун кетади.
              </p>
              <p>
                4.3. <b>Харидорнинг мажбуриятлари:</b>
              </p>
              <p>
                4.3.1. Ушбу Шартнома бўйича тўловларни ўз вақтида амалга ошириш.
              </p>
              <p>
                4.3.2. Макур шартноманинг 2.2-банида кўрсатилган олдиндан тўлов
                амалга оширишдан сўнг Товарни қабул қилиш.
              </p>
              <p>
                4.3.3. Товарни ушбу Шартноманинг 2.1.-бандида кўрсатилган
                Товарнинг умумий қиймати тўламасдан учинчи шахсга ўтказмаслик.
              </p>
              <p>
                4.4. <b>Харидорнинг хуқуқлари:</b>
              </p>
              <p>
                4.4.1. Товарни сотиб олиш вақтида Ўзбекистон Республикасининг
                қонун ҳужжатлари томонидан белгиланган талабларга жавоб
                берадиган маҳсулот ҳақидаги зарур ва ишончли маълумотларни талаб
                қилиш.
              </p>
              <p>
                4.4.2. Товарни қабул қилишдан олдин унинг функцияларини
                текширишни ёки агар бу Товарнинг хусусиятига кўра истисно
                қилинмаса Товардан фойдаланишни кўрсатиб беришни талаб қилиш.
              </p>
              <p>
                4.4.3. Ушбу Шартноманинг 5-бобида белгиланган шартларига мувофиқ
                қафолат ҳолати юзага келган тақдирда, носозликолрани бартараф
                этишни талаб қилиш.
              </p>

              {/* fifth */}
              <p className='topTitle'>5. КАФОЛАТ</p>
              <p>5.1. Товарнинг кафолат муддати:</p>
              <p>- янги Товар учун – 1 (бир) ой;</p>
              <p>- ишлатилган Товар учун – 1 (бир) ҳафта ташил этади.</p>
              <p>
                5.2. Ушбу Шартноманинг 5.1 -бандида кўрсатилган кафолат муддати
                Товар Харидорга топширилган пайтдан бошлаб ҳисобланади.
              </p>
              <p>
                5.3. Томонлар ушбу Шартнома бўйича сотилган товарнинг кафолати
                деганда Тошкент шаҳридаги расмий хизмат кўрсатиш марказида
                техник таъмирлаш тушунилади.
              </p>
              <p>
                5.4. Агар харидорнинг айби билан эмас, балки ишлаб чиқарувчининг
                айби билан кафолат ҳолати юзага келса, Сотувчи зарур
                маслаҳатларни беради ва товарни хизмат кўрсатиш ва носозликларни
                бартараф этиш учун хизмат кўрсатиш марказига топширишни
                ташкиллаштиради.
              </p>
              <p>
                5.5. Ушбу Шартномада қафолат ҳолати деганда қуйидагиларни
                тушунилади:
              </p>
            </div>
          </div>

          {/* right */}
          <div className='halfCard'>
            <div className='halfCardMini'>
              <p>
                3.6. В случае отказа Покупателем от принятия Товара после
                осуществления предоплаты уплаченная сумма предоплаты Покупателю
                не возвращается.
              </p>
              <p>
                3.7. Право собственности на Товар переходит к Покупателю с
                момента оплаты полной стоимости Товара, указанной в п.2.1
                настоящего Договора.
              </p>
              {/* fourth */}
              <br />
              <p className='topTitle'>4.ПРАВА И ОБЯЗАННОСТИ СТОРОН</p>
              <p>
                4.1. <b>Обязанности Продавца:</b>
              </p>
              <p>
                4.1.1. При реализации товара предоставить Покупателю необходимую
                и достоверную информацию о Товаре, отвечающую требованиям норм
                законодательства Республики Узбекистан.
              </p>
              <p>
                4.1.2. Передать Товар в сроки, установленные п.3.4 настоящего
                Договора.
              </p>
              <p>4.1.3. Передать Товар надлежащего качества. </p>
              <p>
                4.2. <b>Права Продавца:</b>
              </p>
              <p>
                4.2.1. Требовать своевременной оплаты Товара на условиях
                настоящего Договора.
              </p>
              <p>
                4.2.2.В случае нарушения условий оплаты, предусмотренных
                настоящим Договором, изъять Товар у Покупателя. При этом,
                денежные средства, ранее оплаченные Покупателем, не
                возвращаются, а идут в счет покрытия убытков Продавца.
              </p>
              <p>
                4.3. <b>Обязанности Покупателя</b>
              </p>
              <p>
                4.3.1. Своевременно осуществлять платежи по настоящему Договору.
              </p>
              <p>
                4.3.2. Принять Товар после проведения предоплаты, указанной в
                4.2.2. настоящего Договора.
              </p>
              <p>
                4.3.3. Не передавать Товар третьим лицам до момента полной
                оплаты стоимости Товара, указанной в п.2.1. настоящего Договора.
              </p>
              <p>
                4.4. <b>Права Покупателя:</b>
              </p>
              <p>
                4.4.1. При покупке Товара требовать необходимую и достоверную
                информацию о Товаре, отвечающую требованиям норм
                законодательства Республики Узбекистан.
              </p>
              <p>
                4.4.2. Требовать проверки функциональности Продукта перед его
                приемкой или демонстрации использования Продукта, если это не
                исключается из-за характера Продукта.
              </p>
              <p>
                4.4.3. В случае возникновения гарантийного случая в соответствии
                с условиями раздела 5 настоящего Договора, требовать исправления
                неполадок.
              </p>

              {/* fifth */}
              <br />
              <p className='topTitle'>5. ГАРАНТИЯ</p>
              <p>5.1. Гарантийный срок на Товар:</p>
              <p>- на новый Товар – 1 (один) месяц;</p>
              <p>- на Товар, бывший в употреблении 1 (одна) неделя.</p>
              <p>
                5.2. Гарантийный рок, указанный в п.5.1. настоящего Договора
                считается с момента передачи Товара Покупателю.
              </p>
              <p>
                5.3. Гарантия товара, реализуемого сторонами по настоящему
                Договору, подразумевает технический ремонт в официальном
                сервисном центре в г. Ташкент.
              </p>
              <p>
                5.4. В случае возникновения гарантийной ситуации по вине
                производителя, а не Покупателя, Продавец предоставит необходимую
                консультацию и организует передачу Товара в сервисный центр для
                обслуживания и устранения неполадок.
              </p>
              <p>
                5.5. По смыслу настоящего Договора гарантийный случай
                подразумевает следующее:
              </p>
            </div>
          </div>
        </div>

        {/* secend big Card */}
        <div className='container'>
          <div className='halfCard'>
            <div className='halfCardMini'>
              <p>
                - ишлаб чиқарувчи айби туфайли юзага келган Товарнинг
                носозликлар;
              </p>
              <p>
                - ишлаб чиқарувчи айби туфайли Товардан мақсадли фойдаланишнинг
                мумкин эмаслиги;
              </p>
              <p>- ишлаб чиқарувчи айби туфайли Товарнинг ишламаслиги.</p>
              <p>
                5.6. Харидор Товарни бошқа мақсадларда ишлатилган ёки уни ишлаб
                чиқарувчининг кўрсатмаларидан фарқли равишда ишлатилган
                тақдирда, Сотувчи Харидор олдида ҳеч қандай кафолат
                мажбуриятларини ўз зиммасига олмайди.
              </p>
              {/* sixth */}
              <p className='topTitle'>6. ТОМОНЛАРНИНГ ЖАВОБГАРЛИГИ</p>

              <p>
                6.1. Ушбу Шартнома бўйича мажбуриятларни бажармаганлик ёки лозим
                даражада бажармаганлик учун томонлар Ўзбекистон Республикасининг
                амалдаги қонун ҳужжатларига мувофиқ жавобгар бўладилар.
              </p>
              <p>
                6.2. Ушбу Шартнома буйича Харидор томонидан тўловларни ўз
                вақтида амалга оширилмаган тақдирида Сотувчи қуйидаги чораларни
                кўриниш мумкин:
              </p>
              <p>
                6.2.1. “IСloud” тизими орқали “Apple” мобил қурилмалари
                блокланиш. Шу билан бирга, блокланишдан олдин мобил қурилмага
                тегишли хабарнома юборилади. Агар хабарнома келган кундан бошлаб
                1 (бир) ой ичида Харидор қарзни ёпилмаса, мобил қурилмани
                блокланади.
              </p>
              <p>
                Харидор Сотувчи томонидан“ICloud”ни ўрнатилиши ва ундан фақат
                виждонсиз харидорларни аниқлаш ва уларни блокланиш мақсадида
                фойдаланишини тушунади ва рози бўлади. Сотувчи “Icloud”дан
                фойдаланиш муносабати билан Харидорнинг шахсий маълумотларига
                кириш ҳуқуқига эмаслигини кафолатлайди. Товарнинг нархни тўлиқ
                тўлагандан сўнг, Сотувчи “ICloud”га бўлган барча хуқуқларни
                харидорга ўтказишни ва ундан фойдаланмасликка кафолат беради.
              </p>
              <p>
                6.2.2. Ушбу Шартнома бўйича муддати ўтган тўловларни Харидорнинг
                барча ҳисобварақлари ва банк карталаридан акцептсиз равишда
                ҳисобдан чиқариш. Шу муносабат билан, ушбу Шартномани имзолаш
                орқали Харидор тўлов кечиктирилган тақдирда пул маблағларини
                ҳисобдан чиқаришга тўлиқ розилигини билдиради.
              </p>
              <p>
                6.2.3. Олдин тўланган пул миқдорни қайтармасдан Товарни
                харидордан олиб кўиш. Шу билан бирга, Харидор томонидан олдин
                тўланган пул миқдори қайтарилмайди ва Сотувчининг йўқотишлари
                қоплаш учун кетади.
              </p>
              <p>
                6.2.4. Жарималар ҳисобга олган ҳолда суд орқали қарзни ундириш.
              </p>
              <p>
                6.2.5. Юқоридаги чоралар Сотувчичинг ихитиёрига кўра амалга
                оширилади. Юкоридаги чоралардан бирини қўллаш қонун хужжатарида
                ва ушбу Шартномада назарда тутилган бошқа чораларни қўллашни
                чекламайди.
              </p>
              <p>
                6.2.6. Агар Харидор ушбу Шартноманинг 4.3.3 –бандни бузилган
                тақдирда Товарни учничи шахсларга топширса ёки бирон бир сабабга
                кўра ўз егалигидан Товарни йўқотса, Товарни тўлаш мажбурияти
                Харидорда қолади.
              </p>
              <p>
                6.2.7. В случае, если Покупатель осуществляет возврат Товара по
                причине невозможности своевременно осуществлять платежи,
                предусмотренные настоящим Договором, Продавец производит
                перерасчет исходя из внесенных Покупателем денежных средств и
                стоимости Товара с учетом его износа на день возврата. При этом,
                разница в стоимости подлежит возмещению соответствующей
                Стороной.
              </p>

              {/* seventh */}
              <p className='topTitle'>7.ФОРС-МАЖОР</p>
              <p>
                7.1. Томонлар ушбу Шартнома бўйича мажбуриятларни қисман ёки
                тўлиқ бажармаганликлари учун жавобгарликдан озод қилинади, агар
                ушбу бажарилмаган ҳолатлар
              </p>
            </div>
          </div>

          {/* right */}
          <div className='halfCard'>
            <div className='halfCardMini'>
              <p>- неполадки Товара, возникшие по вине производителя;</p>
              <p>
                - невозможность использования Товара по назначению по вине
                производителя;
              </p>
              <p>- выход из строя Товара по вине производителя.</p>
              <p>
                5.6. В случае, если Покупатель использует Товар не по назначению
                или использует его вопреки указаниям производителя, Продавец не
                несет перед Покупателем никаких гарантийных обязательств.
              </p>
              {/* sixth */}
              <br />
              <br />
              <p className='topTitle'>6. ОТВЕТСТВЕННОСТЬ СТОРОН</p>

              <p>
                6.1. Стороны несут ответственность за неисполнение или
                ненадлежащее исполнение обязательств по настоящему Договору в
                соответствии с действующим законодательством Республики
                Узбекистан.
              </p>
              <p>
                6.2. В случае несвоевременного внесения платежей Покупателем по
                настоящему Договору Продавец может принять следующие меры:
              </p>
              <p>
                6.2.1. Блокировка мобильных устройств “Apple” через систему
                “ICloud”. При этом, перед блокировкой на мобильное устройство
                будет отправлено соответствующее уведомление. Если Покупатель не
                погасит задолженность в течение 1 (одного) месяца с даты
                уведомления, мобильное устройство будет заблокировано.
              </p>
              <p>
                Покупатель понимает и соглашается с тем, что “ICloud”
                устанавливается и используется Продавцом исключительно в целях
                выявления и блокировки недобросовестных покупателей. Продавец
                гарантирует, что в связи с использованием “ICloud” он не имеет
                доступа к личным данным Покупателя. После полной оплаты
                стоимости Товара, Продавец гарантирует передачу всех прав на
                “ICloud” покупателю и его неиспользование.
              </p>
              <p>
                6.2.2. Списание просроченных платежей по настоящему Договору в
                безакцептном порядке со всех счетов и банковских карт
                Покупателя. В связи с этим, подписывая настоящий договор,
                Покупатель выражет свое полное согласие на списание денежных
                средств, в случае несвоевременной оплаты.
              </p>
              <p>
                6.2.3. Изъятие Товара у Покупателя без возврата ранее уплаченной
                суммы. При этом, ранее уплаченная Покупателем сумма идет в счет
                погашения убытков Продавца.
              </p>
              <p>
                6.2.4. Взыскание задолженности с учетом штрафных санкций в
                судебном порядке.
              </p>
              <p>
                6.2.5. Вышеуказанные меры принимаются на усмотрение Продавца.
                Применение одной из вышеуказанных мер не ограничивает применение
                других мер, предусмотренных законодательными актами и настоящим
                Договором.
              </p>
              <p>
                6.2.6. В случае, если Покупатель, в нарушение п.4.3.3.
                настоящего Договора, передал Товар третьим лицам или каким-то
                иным образом упустил Товар из своего владения, обязательство по
                оплате Товара остается за Покупателем.
              </p>
              <p>
                6.2.7. Харидор ушбу Шартнома бўйича тўловларни ўз вақтида амалга
                оширишнинг иложи бўлмаганлиги сабабли Товарни қайтариб берган
                тақдирда, Сотувчи Харидор томонидан тўланган пул маблағлари ва
                Товарнинг қайтарилган кунидаги эскиришни ҳисобга олган ҳолда
                Товарнинг қийматини қайта ҳисоб-китоб қилади. Бунда тегишли
                Томон нархдаги фарқни қоплайди.
              </p>
              <br />

              {/* seventh */}
              <p className='topTitle'>7.ФОРС-МАЖОР ҲОЛАТЛАРИ</p>
              <p>
                {" "}
                7.1. Стороны освобождаются от ответственности за частичное или
                полное неисполнение своих обязательств по настоящему Договору,
                если это неисполнение является{" "}
              </p>
            </div>
          </div>
        </div>

        {/* secend big Card */}
        <div className='container'>
          <div>
            <div className='container2'>
              <div className='halfCard'>
                <div className='halfCardMini'>
                  <p>
                    шартнома тузилгандан кейин томонлар олдиндан кўра олмайдиган
                    ёки олдини ололмайдиган фавқулодда ҳолатлар (форс-мажор
                    ҳолатлари) натижасида юзага келган бўлса. Форс-мaжoр
                    ҳолатларига қуйидагилар киради: уруш, фавқулодда ҳолат жорий
                    этиш, ёнғин, бошқа авариялар, табиий офат, епидемия,
                    пандемия, иш ташлашлар (локаут, бойкот, блокада), Ўзбекистон
                    Республикасининг норматив-ҳуқуқий ҳужжатлари, махсус
                    комиссиялар ва ваколатли мансабдор шахсларнинг қарорлари,
                    агар уларнинг кучга кириши шартномани бажаришни имконсиз
                    қилади.
                  </p>
                  <p>
                    7.2. Ушбу Шартноманинг 7.1-бандда кўрсатилган ҳолатлар юзага
                    келганда, ҳар бир томон бошқа томонни 3 (уч) календар кун
                    ичида улар тўғрисида ёзма равишда хабардор қилиши керак.
                    Билдиришномада вазиятларнинг моҳияти тўғрисидаги
                    маълумотлар, шунингдек ушбу ҳолатларнинг мавжудлигини
                    тасдиқловчи ва иложи бўлса, уларнинг шартнома бўйича ўз
                    мажбуриятларини бажариш қобилиятига таъсирини баҳолайдиган
                    расмий ҳужжатлар бўлиши керак.
                  </p>
                  <p>
                    7.3. Агар томон ушбу Шартноманинг 7.2-бандида назарда
                    тутилган билдиришномани юбормаса ёки ўз вақтида юбормаса,
                    агар хабар беришнинг иложи йўқлиги Шартноманинг 7.1-бандида
                    кўрсатилган ҳолатларнинг таъсиридан келиб чиқмаса, у иккинчи
                    томонга етказилган зарарларни қоплаши шарт.
                  </p>
                  <p>
                    7.4. Ушбу Шартноманинг 7.1-бандида назарда тутилган ҳолатлар
                    юзага келган ҳолларда, шартнома бўйича мажбуриятларни
                    бажариш муддати ушбу ҳолатлар ва уларнинг оқибатлари амал
                    қиладиган вақтга, лекин бир ойдан ошмасдан, мутаносиб
                    равишда узайтирилади.
                  </p>
                  <p>
                    7.5. Агар ушбу Шартноманинг 7.1 - бандида келтирилган
                    ҳолатлар ва уларнинг оқибатлари бир ойдан ортиқ давом этса,
                    ушбу Шартнома томонларнинг ўзаро розилиги билан бекор
                    қилиниши мумкин.
                  </p>

                  {/* eith */}
                  <p className='topTitle'>8.НИЗОЛАРНИ ҲАЛ ҚИЛИШ ТАРТИБИ</p>
                  <p>
                    8.1. Томонлар ушбу Шартнома юзасидан келиб чиқиши мумкин
                    булган келишмовчилик ва низоларни музокара йўли билан хал
                    қилишга харакат қиладилар.
                  </p>
                  <p>
                    8.2. Ёзма талабномаларни кўриб чиқиш муддати 7 (етти)
                    календар кун ташкил этади.
                  </p>
                  <p>
                    8.3. Ушбу Шартнома бўйича Сотувчидан мобил қурилмага
                    юборилган смс-хабарномаларни (шу жумладан “ICloud орқали”)
                    ёзма шаклга тенг хисобланади.
                  </p>
                  <p>
                    8.4. Агар курсатиб ўтилган келишмовчилик ва низолар
                    музокаралар йули билан хал этилмаса, улар Узбекистон
                    Республикасининг амалдаги қонунчилиги асосида Сотувчи худуди
                    буйича Фуқаролик судда хал этилади.
                  </p>
                  <br />
                  {/* nineth */}
                  <p className='topTitle'>9.ЯКУНИЙ ШАРТЛАР</p>
                  <p>
                    9.1. Ушбу Шартномаси Сотувчидан Товарни олган кундан бошлаб
                    кучга киради ва Харидор ўз мажбуриятларни бажаргунча қадар
                    амал қилади.
                  </p>
                  <p>
                    9.2. Ушбу Шартномага кушимчала ва узгартиришлар киритиш
                    Томонларнинг розилиги билан кушимча келишув битими тузиш
                    йули билан амалга оширилади.
                  </p>
                  <p>
                    9.3. Ушбу Шартномада курсатилмаган холатлар, Узбекистон
                    Республикасининг амалдаги конунчилиги оркали хал этилади.
                  </p>
                  <p>
                    9.4. Томонларнинг манзиллари ва реквизитлари узгартирилган
                    тақдирда, Томон маълумотларни узгартирган пайтдан бошлаб 3
                    (уч) кун ичида бошка Томонни хабардор қилиши шарт.
                  </p>
                  <p>
                    9.5. Ушбу Шартнома бир хил юридик кучга эга булган икки
                    нусхада тузилиб, Томонларнинг хар бирига бир нусхадан
                    берилади.
                  </p>
                </div>
              </div>

              {/* right */}
              <div className='halfCard'>
                <div className='halfCardMini'>
                  <p>
                    следствием чрезвычайных обстоятельств (форс-мажорных
                    обстоятельств), возникших после заключения Договора, которые
                    стороны не могли предвидеть или предотвратить. К
                    обстоятельствам непреодолимой силы относятся: война,
                    чрезвычайное положение, пожар, иные происшествия, стихийное
                    бедствие, эпидемия, пандемия, забастовки (локаут, бойкот,
                    блокада), нормативные правовые акты Республики Узбекистан,
                    решения специальных комиссий и уполномоченных должностных
                    лиц, если их вступление в силу делает невозможным исполнение
                    договора.
                  </p>
                  <p>
                    7.2. В случае наступления обстоятельств, указанных в пункте
                    7.1 настоящего Договора, каждая сторона обязана уведомить об
                    этом другую сторону в письменной форме в течение 3 (трех)
                    календарных дней. Уведомление должно содержать сведения о
                    характере обстоятельств, а также официальные документы,
                    подтверждающие наличие этих обстоятельств и, по возможности,
                    оценку их влияния на возможность выполнения своих
                    обязательств по Договору.
                  </p>
                  <p>
                    7.3. Если сторона не направляет уведомление, предусмотренное
                    пунктом 7.2 настоящего Договора, или не направляет его в
                    срок, за исключением случаев, когда невозможность
                    уведомления вызвана обстоятельствами, указанными в пункте
                    7.1 Договора, она возмещает другой стороне убытки.
                  </p>
                  <p>
                    7.4. При наступлении обстоятельств, предусмотренных пунктом
                    7.1 настоящего Договора, срок исполнения обязательств по
                    договору продлевается пропорционально времени действия этих
                    обстоятельств и их последствий, но не более чем на один
                    месяц.
                  </p>
                  <p>
                    7.5. Если обстоятельства, указанные в пункте 7.1 настоящего
                    Договора, и их последствия длятся более одного месяца,
                    настоящий Договор может быть расторгнут по обоюдному
                    согласию сторон.
                  </p>

                  {/* eith */}
                  <>
                    <br />
                    <p className='topTitle'>8.ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</p>
                    <p>
                      8.1. Любые разногласия и споры, которые могут возникнуть в
                      связи с настоящим Договором, стороны будут стараться
                      решать путем переговоров.
                    </p>
                    <p>
                      8.2. Срок рассмотрения письменных претензий составляет 7
                      (семь) календарных дней.
                    </p>
                    <p>
                      8.3. Направление смс-уведомлений (в том числе через
                      “ICloud”) от Продавца на мобильное устройство по
                      настоящему Договору считается соблюдением письменной
                      формы.
                    </p>
                    <p>
                      8.4. Если указанные разногласия и споры не могут быть
                      разрешены путем переговоров, они будут решаться в
                      гражданском суде согласно действующему законодательству
                      Республики Узбекистан по месторасположению Продавца.
                    </p>
                    <p className='topTitle'>9.ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>
                    <p>
                      9.1. Настоящий Договор вступает в силу с момента получения
                      Товара от Продавца и действует до момента исполнения
                      Покупателем своих обязательств.
                    </p>
                    <p>
                      9.2. Любые изменения и дополнения к настоящему Договору
                      вносятся путем заключения дополнительного соглашения по
                      взаимному согласию Сторон.
                    </p>
                    <p>
                      9.3. Во всем, что не предусмотрено настоящим Договором,
                      стороны руководствуются действующим законодательством
                      Республики Узбекистан.
                    </p>
                    <p>
                      9.4. В случае изменения адресов и реквизитов Сторона
                      обязана уведомить об этом другую Сторону в течение 3
                      (трех) дней с момента изменения сведений.
                    </p>
                    <p>
                      9.5. Настоящий Договор составлен в двух экземплярах,
                      имеющих одинаковую юридическую силу, по одному экземпляру
                      для каждой из Сторон.
                    </p>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* secend big Card */}
        <div className='container'>
          <div>
            <div className='allCard'>
              <p className='topTitle2'>
                10.ТОМОНЛAРНИНГ МAНЗИЛЛAРИ ВA РЕКВИЗИТЛAРИ
              </p>
            </div>

            <div className='container2'>
              <div className='halfCard'>
                <div className='halfCardMini'>
                  <p className='centerTitle'>СОТУВЧИ "ZAFAR TRAVEL CO" МЧЖ</p>
                  <p>
                    <b>Манзил:</b> Тошкент ш., Олмазор тумани, Нурафшон кўчаси,
                    1-уй. 12-хонадон.
                  </p>
                  <p>
                    <b>Банк:</b> АТБ "ASIA ALLIANCE BANK" Шайхонтохур. ф-л.
                  </p>
                  <p>
                    <b>Банк Коди:</b> 01057
                  </p>
                  <p>
                    <b>X/p:</b> 20208000705125899001
                  </p>

                  <p>
                    <b>СТИР:</b> 306737779
                  </p>
                  <p>
                    <b>Директор</b>
                  </p>
                  <p>
                    <b>Нигматов О.Х.</b> __________________
                  </p>

                  <p className='centerTitle'>ПРОДАВЕЦ 000 "ZAFAR TRAVEL CO"</p>
                  <p>
                    <b>Адрес:</b> г. Ташкент, Аламазарский р-н, ул. Нурафшон,
                    1-дом, 12-квартира.
                  </p>
                  <p>
                    <b>Банк:</b> АТБ "ASIA ALLIANCE BANK" Шайхонтохур. ф-л.
                  </p>
                  <p>
                    <b>МФО: 01057:</b> 01057
                  </p>
                  <p>
                    <b>Р/с:</b> 20208000705125899001
                  </p>

                  <p>
                    <b>ИНН:</b> 306737779
                  </p>
                  <p>
                    <b>Директор</b>
                  </p>
                  <p>
                    <b>Нигматов О.Х.</b> __________________
                  </p>
                  <br />
                </div>
              </div>

              {/* right */}
              <div className='halfCard'>
                <div className='halfCardMini'>
                  <p className='centerTitle'>ХAРИДОР</p>
                  <p>
                    <b>Ф.И.Ш:</b> {get(dataLocation, "CardName", "")}
                  </p>
                  <p>
                    <b>Пасспорт id / сериаси ва рақами:</b>{" "}
                    {get(userData, "U_PS", "")}
                  </p>
                  <p>
                    <b>Манзил:</b>{" "}
                    {get(userData, "BPAddresses[0].AddressName", "")},
                    {get(userData, "BPAddresses[0].Street", "")}
                  </p>
                  <br />
                  <br />
                  <p>
                    <b>Тел. рақами:</b> +{get(userData, "Phone1", 0)}
                  </p>
                  <br />

                  <p>
                    <b>Имзо:</b> __________________
                  </p>

                  <p className='centerTitle'>ПОКУПАТЕЛЬ</p>
                  <p>
                    <b>Ф.И.О.</b> {get(dataLocation, "CardName", "")}
                  </p>
                  <p>
                    <b> Серия и номер паспорта/ id:</b>{" "}
                    {get(userData, "U_PS", "")}
                  </p>
                  <p>
                    <b>Адрес:</b>{" "}
                    {get(userData, "BPAddresses[0].AddressName", "")},
                    {get(userData, "BPAddresses[0].Street", "")}
                  </p>
                  <br />
                  <p>
                    <b>Тел.номер:</b> +{get(userData, "Phone1", 0)}
                  </p>
                  <br />
                  <br />

                  <p>
                    <b>Подпись:</b> __________________
                  </p>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* secend big Card */}
        <div className='container'>
          <div className='containerTable'>
            <div className='flex'>
              <i>
                Муддатли тўлов асосида сотиш-харид қилиш шартномасига №
                {get(dataLocation, "DocEntry", 0)} <br />{" "}
                {get(dataLocation, "DocDate", "").slice(-2)}
                {"-"}
                {get(dataLocation, "DocDate", "").slice(-5, -3)}-
                {get(dataLocation, "DocDate", "").slice(0, 4)} даги 1-сон Илова
              </i>
              <i>
                Приложение №1 к Договору купли-продажи на основе рассрочки №
                {get(dataLocation, "DocEntry", 0)} <br /> от{" "}
                {get(dataLocation, "DocDate", "").slice(-2)}
                {"-"}
                {get(dataLocation, "DocDate", "").slice(-5, -3)}-
                {get(dataLocation, "DocDate", "").slice(0, 4)} г.
              </i>
            </div>
            <div className='containerTable'>
              <p className='tableTitle'>ТЎЛОВ ЖАДВАЛИ/ГРАФИК ПЛАТЯЖЕЙ</p>

              <table>
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Сана/Дата</th>
                    <th>Тўлов миқдори/Сумма оплаты (сум)</th>
                    <th>Қолган миқдори/Остаток (сум)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {get(
                            dataLocation,
                            `DocumentInstallments[${i}].DueDate`,
                            ""
                          )}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td colSpan={2}>ЖАМИ/ИТОГО:</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='container2'>
              <div className='halfCard'>
                <div className='halfCardMini'>
                  <p>COTYBЧИ/ПРОДАВЕЦ</p>
                  <p>OOO "ZAFAR TRAVEL CO"</p>
                  <p>Директор</p>
                  <p>Нигматов О.Х. __________________</p>
                </div>
              </div>
              <div className='halfCard'>
                <div className='halfCardMini'>
                  <p>ХАРИДОР/ПОКУПАТЕЛЬ</p>
                  <p>Ф.И.Ш : {get(userData, `CardName`, "")}</p>
                  <br />
                  <p>Имзо __________________</p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DownloadStyle>
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r;
        }}
      />
    </Layout>
  );
};

export default Download;
