import styled from "styled-components";
import colors from "../../assets/style/colors";

const DrawerStyle = styled.div`
  position: absolute;
  top: 5px;
  left: 20px;
  .drawer {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding: 20px 10px;

    .btn {
      width: 100%;
      height: 40px;
      background-color: #dfdfdf;
      border: none;
      margin-bottom: 10px;
      border-radius: 10px;
      color: #000000;
      transition: 0.3s;
    }
    .btn2 {
      width: 100%;
      height: 40px;
      background-color: #dfdfdf;
      border: none;
      margin-bottom: 10px;
      border-radius: 10px;
      color: #000000;
      text-align: center;
    }

    .btnActive {
      width: 100%;
      height: 40px;
      background-color: ${colors.blue2};
      border: none;
      margin-bottom: 10px;
      border-radius: 10px;
      color: #ffffff;
    }

    .btn:hover {
      box-shadow: 2px 3px 10px #3838389a;
    }

    .menuTitle {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .listBtn {
    width: 40px;
    height: 20px;
    background-color: ${colors.mainColor};
    box-shadow: 5px 5px 10px #00000043;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #ffffff;
    font-size: 18px;
  }
  .flag {
    width: 40px;
    height: 20px;
  }
  .between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .btnFlag {
    width: 46%;
    height: 40px;
    background-color: #dfdfdf;
    border: none;
    margin-bottom: 10px;
    border-radius: 10px;
    text-align: center;
  }
  .btnFlag:hover {
    box-shadow: 2px 3px 10px #0000009a;
  }
`;
export default DrawerStyle;
