import React, { memo, useEffect, useState } from "react";
import CreateUserStyle from "./CreateUserStyle";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const CreateUserModal = ({
  getRef = () => {},
  onConfirm = () => {},
  onClose = () => {},
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cardCode, setCardCode] = useState("");
  const [consumer, setConsumer] = useState("");
  const [passport, setPassport] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [male, setMale] = useState("");

  useEffect(() => {
    const ref = {
      open: () => setIsOpenModal(true),
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
  }, []);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel='Example Modal'
      ariaHideApp={false}
    >
      <CreateUserStyle>
        <div className='card'>
          <h2>
            {"Создать пользователя"}
            {"!"}
          </h2>
          <input
            type='text'
            placeholder='Card Kod'
            className='input'
            defaultValue={cardCode}
            onChange={(v) => setCardCode(v.target.value)}
          />
          <input
            type='text'
            placeholder='Mijoz'
            className='input'
            defaultValue={consumer}
            onChange={(v) => setConsumer(v.target.value)}
          />
          <input
            type='text'
            placeholder='Passport'
            className='input'
            defaultValue={passport}
            onChange={(v) => setPassport(v.target.value)}
          />
          <input
            type='text'
            placeholder='Address'
            className='input'
            defaultValue={address}
            onChange={(v) => setAddress(v.target.value)}
          />
          <input
            type='number'
            placeholder='Phone'
            className='input'
            defaultValue={phone}
            onChange={(v) => setPhone(v.target.value)}
          />

          <input
            type='radio'
            id='male'
            name='fav_language'
            value='Male'
            onChange={(v) => setMale(v.target.value)}
          />

          <label htmlFor='male'>Мужчина</label>
          <input
            type='radio'
            id='female'
            name='fav_language'
            value='Female'
            onChange={(v) => setMale(v.target.value)}
          />
          <label htmlFor='female'>Женщина</label>

          <div className='centerCard'>
            <button
              className='btnN'
              onClick={() => {
                onClose();
                setIsOpenModal(false);
              }}
            >
              {"Нет"}
            </button>
            <button
              className='btnY'
              disabled={
                cardCode.length <= 0 ||
                consumer.length <= 0 ||
                passport.length <= 0 ||
                address.length <= 0 ||
                phone.length <= 0 ||
                male.length <= 0
              }
              onClick={() => {
                onConfirm(consumer, passport, male, address, phone, cardCode);
              }}
            >
              {"Да"}
            </button>
          </div>
        </div>
      </CreateUserStyle>
    </Modal>
  );
};

export default memo(CreateUserModal);
