import React, { useEffect, useRef, useState } from "react";
import RecoveryChartStyle from "./RecoveryChartStyle";
import { ErrorModal } from "../../components/Modal";
import ReactApexChart from "react-apexcharts";
import Layout from "../../components/Layout";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { get } from "lodash";
import axios from "axios";

const RecoveryChart = () => {
  const { getMe } = useSelector((state) => state.main);

  const formattedDate = moment().format("YYYY-MM-DD");

  const errorRef = useRef();

  const [data, setData] = useState([]);
  const [cl, setCl] = useState(0);
  const [op, setOp] = useState(0);
  const [sum, setSum] = useState(0);

  useEffect(() => {
    getCharts(formattedDate);
  }, []);

  const getCharts = async (date) => {
    const start_date = moment(date).startOf("month").format("YYYY-MM-DD");
    const end_date = moment(date).endOf("month").format("YYYY-MM-DD");

    await axios
      .get(
        `https://api.bis-pro.com/api/dunningStats?date1='${start_date}T00:00:00Z'&date2='${end_date}T00:00:00Z'`
      )
      .then((res) => {
        const resData = get(res, "data.value", []);
        setData(resData);

        let sotilgan = 0;
        let sotilmagan = 0;
        let umumiy = 0;
        for (let i = 0; i < resData.length; i++) {
          sotilgan = sotilgan + resData[i].Closed;
          sotilmagan = sotilmagan + resData[i].Open;
          umumiy = umumiy + resData[i].Count;
        }
        setCl(sotilgan);
        setOp(sotilmagan);
        setSum(umumiy);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  return (
    <Layout>
      <RecoveryChartStyle>
        <div className='container'>
          <div className='containerMini'>
            <p className='topTitle'>Statistika</p>

            <input
              type='date'
              className='topInput'
              onChange={(e) => {
                const newDate = e.target.value;
                getCharts(newDate);
              }}
              defaultValue={formattedDate}
            />

            {get(getMe, "Department2.Name", "") === "Boshqaruv" ? (
              <div className='leftCard'>
                <div className='leftcardMini'>
                  <p>
                    Umumiy soni: <b>{sum}</b>
                  </p>
                  <ReactApexChart
                    options={{
                      chart: {
                        width: 380,
                        type: "pie",
                      },
                      colors: ["#28D03E", "#FF6347"],
                      fill: {
                        colors: ["#28D03E", "#FF6347"],
                      },
                      labels: [
                        `Sotilgan tovarlar: ${cl}`,
                        `Tugallanmagan: ${op}`,
                      ],
                      responsive: [
                        {
                          breakpoint: 480,
                          options: {
                            chart: {
                              width: 200,
                            },
                            legend: {
                              position: "bottom",
                            },
                          },
                        },
                      ],
                    }}
                    series={[cl, op]}
                    type='pie'
                  />
                </div>
              </div>
            ) : null}

            {data.map((v, i) => {
              return v.U_Employee === get(getMe, "EmployeeID", "") ||
                get(getMe, "Department2.Name", "") === "Boshqaruv" ? (
                <div className='leftCard2' key={i}>
                  <div className='between'>
                    <p className='operatorTitle'>
                      {get(v, "firstName", null) === null
                        ? "Undiruvchisiz"
                        : get(v, "firstName", "Name")}{" "}
                      {get(v, "lastName", null) === null
                        ? ""
                        : get(v, "lastName", "Name")}
                    </p>
                    <p className='operatorTitle'>
                      {"Umumiy soni-"}
                      <b> {get(v, "Count", "0")}</b>
                    </p>
                  </div>

                  <ReactApexChart
                    options={{
                      chart: {
                        width: 980,
                        type: "pie",
                      },
                      colors: ["#28D03E", "#FF6347"],
                      fill: {
                        colors: ["#28D03E", "#FF6347"],
                      },
                      labels: [
                        `Sotilgan tovarlar: ${v.Closed}`,
                        `Tugallanmagan: ${v.Open}`,
                      ],
                      responsive: [
                        {
                          breakpoint: 280,
                          options: {
                            legend: {
                              position: "bottom",
                            },
                          },
                        },
                      ],
                    }}
                    series={[v.Closed, v.Open]}
                    type='pie'
                  />
                </div>
              ) : null;
            })}
          </div>
        </div>
      </RecoveryChartStyle>
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r;
        }}
      />
    </Layout>
  );
};

export default RecoveryChart;
