import React, {useState, useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorModal} from '../../components/Modal';
import {useNavigate} from 'react-router-dom';
import Button from '../../components/Button';
import {main} from '../../store/slices';
import LoginStyle from './LoginStyle';
import {get} from 'lodash';
import api from '../../api';

const Login = () => {
  const {setMe, setToken} = main.actions;
  const {info} = useSelector(state => state.main);

  const loginValue = get(info, 'login', '');
  const passwordValue = get(info, 'password', '');

  const errorRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, setLogin] = useState(loginValue);
  const [password, setPassword] = useState(passwordValue);
  const [isLoading, setIsLoading] = useState(false);

  const loginProfile = useCallback(() => {
    setIsLoading(true);

    const prod1 = {
      CompanyDB: 'PROBOX_DEV_2',
      UserName: 'B',
      Password: 'q1w2e3r4T%',
      Language: 24,
    };

    const prod = {
      CompanyDB: 'PROBOX_PROD_3',
      UserName: 'B',
      Password: 'q1w2e3r4T%',
      Language: 24,
    };

    api
      .post('/Login', prod)
      .then(res => {
        dispatch(setToken(get(JSON.parse(res.data), 'SessionId', '')));
        secure();
      })
      .catch(err => {
        errorRef.current?.open('Login yoki parol xato');
        console.log('err', err);
      });
  }, [login, password]);

  const secure = () => {
    api
      .get(
        `EmployeesInfo?$expand=Department&$select=EmployeeID,LastName,FirstName,Department2,U_CashAccount,U_CardAccount,U_TransAccount,U_Warehouse&$filter=EmployeeCode eq '${login}' and ExternalEmployeeNumber eq '${password}'`,
      )
      .then(res => {
        const resData = get(JSON.parse(res.data), 'value[0]', {});
        console.log('res w', resData);
        if (get(resData, 'EmployeeID', '')) {
          if (get(resData, 'Department2.Name', '') === 'Sotuv') {
            navigate('/allProducts');
          } else if (get(resData, 'Department2.Name', '') === 'Undiruv') {
            navigate('/recovery');
          } else {
            navigate('/allProducts');
          }
          dispatch(setMe(resData));
        } else {
          errorRef.current?.open('Login yoki parol xato');
        }
        setIsLoading(false);
      })
      .catch(err => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), 'error.message', ''),
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <LoginStyle>
        <div className="container">
          <div style={{width: '100%'}}>
            <div className="card">
              <div>
                <label htmlFor="login" className="label">
                  Логин
                </label>
              </div>
              <input
                type="text"
                id="login"
                placeholder="Логин"
                defaultValue={loginValue}
                className="input"
                onChange={e => setLogin(e.target.value)}
              />
            </div>
            <div className="card">
              <div>
                <label htmlFor="password" className="label">
                  Пароль
                </label>
              </div>
              <input
                type="text"
                id="password"
                placeholder="Пароль"
                defaultValue={passwordValue}
                className="input"
                onChange={e => setPassword(e.target.value)}
              />
            </div>

            <div style={{textAlign: 'center'}}>
              <Button
                onClick={() => loginProfile()}
                children={'Вход'}
                isLoading={isLoading}
                btnStyle={{width: 100}}
                hoverBtnStyle={{width: 100}}
              />
            </div>
          </div>
        </div>
      </LoginStyle>
      <ErrorModal
        getRef={r => {
          errorRef.current = r;
        }}
      />
    </>
  );
};

export default Login;
