import React, { memo, useEffect, useState } from "react";
import SuccessModalStyle from "./SuccessModalStyle";
import Modal from "react-modal";
import SuccessImage from "../../../assets/images/success.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SuccessModal = ({ getRef = () => {}, onConfirm = () => {} }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [successTitle, setSuccessTitle] = useState("Выполнено успешно");

  useEffect(() => {
    const ref = {
      open: (t) => {
        setIsOpenModal(true);
        setSuccessTitle(t);
      },
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
  }, []);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel='Example Modal'
      ariaHideApp={false}
    >
      <SuccessModalStyle>
        <div className='card'>
          <img src={SuccessImage} alt='success' className='img' />
          <h2>
            {successTitle}
            {"!"}
          </h2>

          <div className='centerCard'>
            <button
              className='btnY'
              onClick={() => {
                onConfirm();
                setIsOpenModal(false);
              }}
            >
              {"Понятно"}
            </button>
          </div>
        </div>
      </SuccessModalStyle>
    </Modal>
  );
};

export default memo(SuccessModal);
