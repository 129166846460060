const DollarToWord = (num) => {
  console.log(num);
  const ones = [
    "",
    "Бир",
    "Икки",
    "Уч",
    "Торт",
    "Беш",
    "Олти",
    "Ётти",
    "Саккиз",
    "Тўққиз",
  ];
  const tens = [
    "",
    "Ўн",
    "Йигирма",
    "Ўттиз",
    "Қирқ",
    "Еллик",
    "Олтмуш",
    "Йетмуш",
    "Саксон",
    "Тўқсон",
  ];
  const hundreds = ["", "Минг", "Миллион", "Миллиард"];

  function convertToWords(n, level) {
    if (n === 0) return "";

    let words = "";

    const hundredsDigit = Math.floor(n / 100);
    const tensDigit = Math.floor((n % 100) / 10);
    const onesDigit = n % 10;

    if (hundredsDigit > 0) {
      words += ones[hundredsDigit] + " Yuz ";
    }

    if (tensDigit > 0) {
      words += tens[tensDigit] + " ";
    }

    if (onesDigit > 0) {
      words += ones[onesDigit];
    }

    if (level > 0) {
      words += " " + hundreds[level];
    }

    return words;
  }

  if (num === 0) {
    return "nol";
  }

  let result = "";
  let level = 0;

  while (num > 0) {
    const chunk = num % 1000;
    if (chunk !== 0) {
      result = convertToWords(chunk, level) + " " + result;
    }

    num = Math.floor(num / 1000);
    level++;
  }

  return result.trim();
};

export default DollarToWord;

// const number = 20560780;
// const words = numberToWordsUzbek(number);
// console.log(words);
