import styled from "styled-components";
import colors from "../../assets/style/colors";

const ButtonStyle = styled.div`
  .btn {
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${colors.mainColor};
    color: #ffffff;
    font-size: 15px;
    border: none;
    min-width: 50px;
    height: 40px;
  }
  .btn:hover {
    background-color: ${colors.mainColor};
  }
  .btn2 {
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${colors.mainColor};
    color: #ffffff;
    font-size: 15px;
    border: none;
    min-width: 50px;
    height: 40px;
  }
`;
export default ButtonStyle;
