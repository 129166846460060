import React, { useCallback, useEffect, useRef, useState } from "react";
import { SuccessModal, ErrorModal, PaymentModal } from "../../components/Modal";
import numberWithSpaces from "../../helpers/numberWithSpaces";
import { useLocation, useNavigate } from "react-router-dom";
import NoDollar from "../CreateProducts/NoDollar";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import ViewItemStyle from "./ViewItemStyle";
import { useSelector } from "react-redux";
import { get } from "lodash";
import api from "../../api";

const ViewItem = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const successModalRef = useRef();
  const errorRef = useRef();
  const noDollarRef = useRef();
  const paymentRef = useRef();

  const { getMe } = useSelector((state) => state.main);
  const data = get(location, "state", {});
  const DocEntry = get(location, "state.Invoices.DocEntry", 0);

  const getNoDollarRef = useCallback((ref) => {
    noDollarRef.current = ref;
  }, []);

  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [deviceInfoData, setDeviceInfoData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);

  const [sumPay, setSumPay] = useState(0);
  const [dollar, setDollar] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  useEffect(() => {
    getInformationOfItem();
    getInfoOFDevice();
  }, []);

  const getInformationOfItem = () => {
    setIsLoading(true);
    api
      .get(`SQLQueries('getInstallments')/List?docEntry='${DocEntry}'`, {
        headers: {
          Prefer: "odata.maxpagesize=50",
        },
      })
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setTableData2(resData);
        setTableData(resData);
        setIsLoading(false);
        let a = JSON.parse(JSON.stringify(resData));
        let allSum = 0;
        for (let i = 0; i < a.length; i++) {
          allSum = allSum + a[i].PaidToDate;
        }
        setSumPay(allSum);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  const getInfoOFDevice = () => {
    setIsLoading2(true);

    api
      .get(`Invoices(${DocEntry})`)
      .then((res) => {
        const resData = get(JSON.parse(res.data), "DocumentLines[0]", []);
        setDeviceInfoData(resData);
        setIsLoading2(false);
        setDownloadData(JSON.parse(res.data));
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  const createPayment = (sum, typeOfPayment, valuta, courseDollar) => {
    setIsLoading(true);
    setDollar(Number(courseDollar));
    let kiritilganSum =
      valuta === "UZS" ? Number(sum) / Number(courseDollar) : Number(sum);
    let kiritilganSum2 =
      valuta === "UZS" ? Number(sum) / Number(courseDollar) : Number(sum);

    let filteredData = tableData.filter((v) => {
      return v.InsTotal !== v.PaidToDate;
    });

    let filteredData2 = tableData2.filter((v) => {
      return v.InsTotal !== v.PaidToDate;
    });

    let aa = [...filteredData];
    let goingBack = JSON.parse(JSON.stringify(filteredData));

    for (let i = 0; i < filteredData.length; i++) {
      let left = Number(filteredData[i].InsTotal);
      let right = Number(filteredData[i].PaidToDate);

      let left2 = Number(filteredData2[i].InsTotal);
      let right2 = Number(filteredData2[i].PaidToDate);

      if (kiritilganSum + right >= left) {
        aa[i].PaidToDate = left;
        goingBack[i].PaidToDate =
          Number(goingBack[i].InsTotal) - Number(goingBack[i].PaidToDate);
        kiritilganSum = kiritilganSum - (left - right);
        kiritilganSum2 = kiritilganSum2 + right2 - left2;
      } else if (kiritilganSum + right < left) {
        aa[i].PaidToDate = kiritilganSum + right;
        goingBack[i].PaidToDate = kiritilganSum2;
        kiritilganSum = 0;
        kiritilganSum2 = 0;
      }
    }

    let dataPFilter = goingBack.filter((v) => {
      return v.PaidToDate > 0;
    });

    let dataPFilterMain = dataPFilter.map((v) => {
      return {
        DocEntry: DocEntry, // Qaysi sotuvga to'lanyapti.
        InstallmentId: v.InstlmntID, // birinchi oyga
        SumApplied: v.PaidToDate, // birinchi oyga to'lo'v
      };
    });

    api
      .post(`IncomingPayments`, {
        CardCode: get(data, "Invoices.CardCode", 0), // Kim to'layapti. BusinessPartner->CardCode
        CashSum: Number(sum),
        CashAccount: getMe[typeOfPayment],
        PaymentInvoices: dataPFilterMain,
        BankChargeAmount: 0,
        DocCurrency: valuta,
      })
      .then(() => {
        setIsLoading(false);
        successModalRef.current?.open("Платеж прошел успешно");
      })
      .catch((err) => {
        setIsLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  const cancel = () => {
    navigate("/allProducts");
  };

  return (
    <Layout>
      <ViewItemStyle>
        <div className='container'>
          <div className='betweenCard'>
            <p className='fio'>ФИО</p>
            <input
              type='text'
              className='inputDefault'
              value={`${get(data, "Invoices.CardName", "Клиент")}`}
              disabled={true}
            />

            <p className='fio'>Продукт</p>
            <input
              type='text'
              className='inputDefault'
              value={`${get(
                data,
                "Invoices/DocumentLines.ItemDescription",
                "Товар"
              )}`}
              disabled={true}
            />

            <p className='fio'>Серийный номер</p>
            <input
              type='text'
              className='inputDefault'
              value={`${get(
                deviceInfoData,
                "SerialNumbers[0].InternalSerialNumber",
                0
              )}`}
              disabled={true}
            />

            <p className='fio'>Цвет</p>
            <input
              type='text'
              className='inputDefault'
              value={`${get(deviceInfoData, "U_color", "Цвет не найден")}`}
              disabled={true}
            />
          </div>

          <div className='betweenCard'>
            <p className='fio'>Состояние</p>
            <input
              type='text'
              className='inputDefault'
              value={`${get(
                deviceInfoData,
                "U_condition",
                "Состояние не найден"
              )}`}
              disabled={true}
            />

            <p className='fio'>Текущая цена</p>
            <input
              type='number'
              placeholder='Текущая цена'
              className='inputDefault'
              value={`${get(
                deviceInfoData,
                "GrossBuyPrice",
                "Текущая цена не найден"
              )}`}
              disabled={true}
            />

            <p className='fio'>Цена</p>
            <input
              type='number'
              placeholder='Цена'
              className='inputDefault'
              value={`${get(deviceInfoData, "GrossTotal", "Цена не найден")}`}
              disabled={true}
            />
          </div>

          <div className='betweenCard'>
            <div className='betweenCard2'>
              <p>Kurs: {numberWithSpaces(dollar)} UZS</p>
              <p>
                Qarz:{" "}
                <span className='debt'>
                  -{(get(deviceInfoData, "GrossTotal", 0) - sumPay).toFixed(2)}
                </span>{" "}
                USD
              </p>
            </div>
            <div className='center'>
              <Button
                btnStyle={{ backgroundColor: "#243AB5" }}
                onClick={() => paymentRef.current?.open()}
                isLoading={isLoading || isLoading2}
                hoverBtnStyle={{ backgroundColor: "#243AB5" }}
              >
                Платить
              </Button>
            </div>

            <table className='table'>
              <thead>
                <tr>
                  <th>N</th>
                  <th>Дата</th>
                  <th>Оплата</th>
                  <th>Оплаченный</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((v, i) => {
                  let a = v.DueDate.split("");
                  a.splice(4, 0, "-");
                  a.splice(7, 0, "-");
                  a.join();
                  return (
                    <tr key={i}>
                      <td>{i}</td>
                      <td>{a}</td>
                      <td>{Number(v.InsTotal).toFixed(2)}</td>
                      <td>{v.PaidToDate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className='bottomCard'>
            <Button btnStyle={{ backgroundColor: "red" }} onClick={cancel}>
              Назад
            </Button>

            <Button
              btnStyle={{ marginLeft: 20, width: 200 }}
              onClick={() =>
                navigate("/download", { state: { data: downloadData } })
              }
            >
              Загрузить договор
            </Button>
          </div>
        </div>
      </ViewItemStyle>
      <>
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r;
          }}
        />
        <PaymentModal
          getRef={(r) => {
            paymentRef.current = r;
          }}
          onConfirm={(sum, typeOfPayment, valuta, courseDollar) =>
            createPayment(sum, typeOfPayment, valuta, courseDollar)
          }
        />
        <SuccessModal
          getRef={(r) => {
            successModalRef.current = r;
          }}
          onConfirm={() => successModalRef.current?.close()}
        />
        <NoDollar getRef={getNoDollarRef} />
      </>
    </Layout>
  );
};

export default ViewItem;
