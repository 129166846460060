import React, { useEffect, useRef, useState } from "react";
import { ErrorModal, SuccessModal, WarningModal } from "../../components/Modal";
import Progressbar from "../../components/Progress";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import RecoveryStyle from "./RecoveryStyle";
import { useSelector } from "react-redux";
import { get } from "lodash";
import api from "../../api";
import moment from "moment";
import axios from "axios";

const Recovery = () => {
  const { getMe } = useSelector((state) => state.main);

  const navigate = useNavigate();

  const startOfMonth = moment().clone().startOf("month");
  const endOfMonth = moment().clone().endOf("month");
  const startDateFormatted = startOfMonth.format("YYYY-MM-DD");
  const endDateFormatted = endOfMonth.format("YYYY-MM-DD");

  const errorRef = useRef();
  const successRef = useRef();
  const warningRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isProtsent, setIsProtsent] = useState(false);

  const [status, setStatus] = useState("all");
  const [clientName, setClientName] = useState("");
  const [userCardCode, setUserCardCode] = useState("");
  const [numberProtsent, setnumberProtsent] = useState(1);
  const [dateStart, setDateStart] = useState(startDateFormatted);
  const [dateEnd, setDateEnd] = useState(endDateFormatted);

  const [executer, setExecuter] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [doceAndInsData, setDoceAndInsData] = useState([]);

  const [data, setData] = useState({
    data: [],
    currentPage: 0, //20
    nextPage: 0, //30
    oldPage: 0,
  });

  useEffect(() => {
    recovery(0, 0, dateStart, dateEnd);
    getAllGetterMoney();
    doceAndIns();
  }, []);

  const getAllGetterMoney = () => {
    api
      .get(
        `$crossjoin(EmployeesInfo, Departments)?$filter=Departments/Name eq 'Undiruv' and EmployeesInfo/Department eq Departments/Code&$expand=EmployeesInfo($select=EmployeeID,LastName,FirstName,U_CashAccount,U_CardAccount,U_TransAccount),Departments($select=Name,Code)`,
        {
          headers: {
            Prefer: "odata.maxpagesize=90000",
          },
        }
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setExecuter(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  const doceAndIns = () => {
    api
      .get(
        `SQLQueries('latePaymentsMin')/List?date1='${startDateFormatted}T00:00:00Z'&date2='${endDateFormatted}T00:00:00Z'`,
        {
          headers: {
            Prefer: "odata.maxpagesize=90000",
          },
        }
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setDoceAndInsData(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  const recovery = async (currentPage = 0, oldDataPage = 0, startD, endD) => {
    setIsLoading(true);
    await axios
      .get(
        `https://api.bis-pro.com/api/installments?date1='${startD}T00:00:00Z'&date2='${endD}T00:00:00Z'${
          userCardCode.length > 0 ? `&CardCode='${userCardCode}'` : ""
        }${
          get(getMe, "Department2.Name", "") === "Boshqaruv"
            ? ""
            : `&U_Employee=${get(getMe, "EmployeeID", "")}`
        }${status.length <= 0 ? "" : `&status=${status}`}&$skip=${currentPage}`,
        {
          headers: {
            Prefer: "odata.maxpagesize=10",
          },
        }
      )
      .then((res) => {
        const resData = get(res, "data.value", []);
        setData({ data: resData });
        const nextPage = Number(
          get(res.data, "@odata.nextLink", "skip=0").split("skip=")[1]
        );

        if (oldDataPage === 0) {
          setData({
            data: [...resData],
            currentPage: currentPage === 0 ? 0 : data.nextPage,
            nextPage: nextPage === 0 ? data.currentPage : nextPage,
            oldPage: nextPage === 0 ? data.currentPage : nextPage - 20,
          });
        } else {
          setData({
            data: [...resData],
            currentPage: data.nextPage - 20,
            nextPage: nextPage,
            oldPage: nextPage < data.currentPage ? nextPage : nextPage - 20,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
        setIsLoading(false);
      });
  };

  const newDatas = () => {
    if (data.nextPage > data.currentPage) {
      recovery(data.nextPage, 0, dateStart, dateEnd);
    } else {
      warningRef.current?.open("Boshqa ma'lumot yo'q");
    }
  };

  const oldData = () => {
    if (data.oldPage < 0) {
      warningRef.current?.open("Boshqa ma'lumot yo'q");
    } else {
      recovery(data.oldPage, 1, dateStart, dateEnd);
    }
  };

  const viewItem = (v) => {
    navigate("/viewRecoveryItem", {
      state: {
        data: v,
        executer,
      },
    });
  };

  const searchUser = (a = "") => {
    setClientName(a);
    setUserCardCode(a.length <= 0 && "");
    api
      .get(
        `BusinessPartners?$select=CardCode,CardName,Address,Phone1,GroupCode&$filter=CardType eq 'cCustomer' and (contains(CardName, '${a}') or contains(Phone1, '${a}'))`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setCustomerData(resData);
        if (a.includes(" , ")) {
          setUserCardCode(a.split(" , ")[1]);
        }
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  // debounce

  useEffect(() => {
    const delay = 1000;
    let timeoutId;
    if (clientName) {
      timeoutId = setTimeout(() => {
        searchUser(clientName);
      }, delay);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [clientName]);

  const handleChange = (e) => {
    const newSearchTerm = e.target.value;
    setClientName(newSearchTerm);
  };

  const distribution = () => {
    distributionMain(0, 50, 1);
    setIsProtsent(true);
  };

  const distributionMain = (currentN, nextN, someN) => {
    const dunners = executer.map((v) => {
      return get(v, "EmployeesInfo.EmployeeID", 0);
    });

    let someData = doceAndInsData.slice(currentN, nextN).map((v, i) => {
      return {
        DocEntry: v.DocEntry,
        InstallmentId: v.InstlmntID,
      };
    });
    console.log(someN, currentN, nextN, someData);

    api
      .post(`script/test/assign_dunners`, {
        installments: someData,
        dunners,
        Origin: window.origin,
      })
      .then(() => {
        if (Math.floor(doceAndInsData.length / 50) + 1 > someN) {
          console.log("success");
          distributionMain(currentN + 50, nextN + 50, someN + 1);
          setnumberProtsent(someN + 1);
          console.log("a", someN + 1);
        } else {
          alert("Amaliyot to'liq yuklandi");
          setIsProtsent(false);
        }
      })
      .catch((err) => {
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
        setIsProtsent(false);
      });
  };

  return (
    <Layout>
      <RecoveryStyle>
        <div className='container'>
          <div style={{ width: "100%" }}>
            <p className='topTitle'>Undiruv jadvali</p>
            <div className='topCard'>
              <div className='topCardMini'>
                <div>
                  <p className='topTitleInput'>FIO</p>

                  <input
                    type='text'
                    list='client'
                    className='topInput'
                    value={clientName}
                    onChange={handleChange}
                  />

                  <datalist id='client'>
                    {customerData.map((v, i) => (
                      <option
                        key={i}
                        value={`${get(v, "CardName", "")} , ${get(
                          v,
                          "CardCode",
                          ""
                        )}`}
                      />
                    ))}
                  </datalist>
                </div>

                <div>
                  <p className='topTitleInput'>Boshlanish sanasi</p>
                  <input
                    type='date'
                    className='topInputDate'
                    defaultValue={dateStart}
                    onChange={(v) => setDateStart(v.target.value)}
                  />
                </div>
                <div>
                  <p className='topTitleInput'>Tugash sanasi</p>
                  <input
                    type='date'
                    className='topInputDate'
                    defaultValue={dateEnd}
                    onChange={(v) => setDateEnd(v.target.value)}
                  />
                </div>
                <div>
                  <p className='topTitleInput'>Tolash</p>
                  <select
                    name='payed'
                    id='payed'
                    className='sellect'
                    onChange={(v) => setStatus(v.target.value)}
                  >
                    <option value='all'>Hammasi</option>
                    <option value='closed'>{"To'landi"}</option>
                    <option value='open'>{"To'lanmadi"}</option>
                  </select>
                </div>
              </div>
              <Button
                isLoading={isLoading}
                onClick={() => recovery(0, 0, dateStart, dateEnd)}
              >
                Search
              </Button>
            </div>

            <table className='table'>
              <thead>
                <tr>
                  <th>Код клиента</th>
                  <th>Имя Клиента</th>
                  <th>Товар</th>
                  <th>Деньги</th>
                  <th>Дата продажи</th>
                  <th>Исполнитель</th>
                </tr>
              </thead>
              <tbody>
                {data.data.map((v, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => viewItem(v)}
                      className={
                        get(v, "INV6.PaidToDate", 0) === get(v, "InsTotal", 0)
                          ? "greenRaw"
                          : "redRaw"
                      }
                    >
                      <td>{get(v, "CardCode", "Код покупателя")}</td>
                      <td>{get(v, "CardName", "Имя покупателя")}</td>
                      <td>{get(v, "Dscription", "")}</td>
                      <td>{get(v, "DocTotal", "").slice(0, -5)} USD</td>
                      <td>{get(v, "DueDate", "").slice(0, 10)}</td>
                      <td>
                        {get(v, "firstName", "")} {get(v, "lastName", "")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className='between'>
              <div className='flex'>
                <Button onClick={oldData}>{"<"}</Button>
                <Button onClick={newDatas}>{">"}</Button>
              </div>
              {doceAndInsData.length > 1 ? (
                <Button
                  className='btnSome'
                  onClick={distribution}
                  isLoading={isLoading}
                  btnStyle={{ width: 300 }}
                  hoverBtnStyle={{ width: 300 }}
                >
                  {"Undiruvchilarni taqsimlash"}
                </Button>
              ) : null}
            </div>

            {isProtsent ? (
              <>
                <p className='progres'>Iltimos, jarayon tugaguncha kuting</p>

                <Progressbar
                  bgcolor='green'
                  progress={
                    ((100 / Math.floor(doceAndInsData.length / 50)).toFixed(2) -
                      1) *
                    numberProtsent
                  }
                  height={23}
                />
              </>
            ) : null}
          </div>
        </div>
      </RecoveryStyle>
      <>
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r;
          }}
        />
        <SuccessModal
          getRef={(r) => {
            successRef.current = r;
          }}
        />
        <WarningModal
          getRef={(r) => {
            warningRef.current = r;
          }}
        />
      </>
    </Layout>
  );
};

export default Recovery;
