import React, { memo, useEffect, useState } from "react";
import NoDollarStyle from "./NoDollarStyle";
import ErrorImage from "../../../assets/images/error.png";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const NoDollar = ({ title = "", getRef }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  useEffect(() => {
    const ref = {
      open: () => setIsOpenModal(true),
      close: () => setIsOpenModal(false),
    };
    getRef(ref);
  }, []);

  return (
    <Modal
      isOpen={isOpenModal}
      onRequestClose={() => setIsOpenModal(false)}
      style={customStyles}
      contentLabel='Example Modal'
      ariaHideApp={false}
    >
      <NoDollarStyle>
        <div className='card'>
          <img src={ErrorImage} alt='error image' className='img' />
          <h1>{"Ошибка"}</h1>
          <p className='title'>{"Введите доллары на сегодняшнюю дату в SAP"}</p>
          <div className='centerCard'>
            <button className='btnN' onClick={() => setIsOpenModal(false)}>
              {"Понятно"}
            </button>
          </div>
        </div>
      </NoDollarStyle>
    </Modal>
  );
};

export default memo(NoDollar);
