import React, { useEffect, useRef, useState } from "react";
import CreateProductsStyle from "./CreateProductsStyle";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { get } from "lodash";
import { BsSearch } from "react-icons/bs";
import time from "../../helpers/time";
import {
  ErrorModal,
  SuccessModal,
  WarningModal,
  CreateUser,
} from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import ChangeDate from "./ChangeDate";
import { main } from "../../store/slices";
import Layout from "../../components/Layout";
import moment from "moment";

const CreateProducts = () => {
  const { setDate } = main.actions;
  const { getMe } = useSelector(state => state.main);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeDateRef = useRef();
  const errorRef = useRef();
  const infoNotEndedRef = useRef();
  const successModalRef = useRef();
  const succesCreateUserRef = useRef();
  const createUserRef = useRef();

  const [customerData, setCustomerData] = useState([]);
  const [productNameData, setProductNameData] = useState([]);
  const [seriaNumberData, setSeriaNumberData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [simpleMonthData, setsimpleMonthData] = useState([]);
  const [vipMonthData, setVipMonthData] = useState([]);

  const [price, setPrice] = useState("");
  const [color, setColor] = useState("");
  const [month, setMonth] = useState("");
  const [monthB2B, setMonthB2B] = useState("");
  const [customer, setCustomer] = useState("");
  const [condition, setCondition] = useState("");
  const [productName, setProductName] = useState("");
  const [seriaNumber, setSeriaNumber] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [userCardCode, setUserCardCode] = useState("");
  const [seriaNumberMain, setSeriaNumberMain] = useState("");
  const [systemSerialNumber, setSystemSerialNumber] = useState("");
  const [minusedMoney, setMinusedMoney] = useState(0);
  const [productCode, setProductCode] = useState(0);
  const [dateIndex, setDateIndex] = useState(0);
  const [viewData, setViewData] = useState({});

  const [isCalculated, setIsCalculated] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(false);

  useEffect(() => {
    getSimpleMonth();
    getVIPMonth();
  }, []);

  const getSimpleMonth = () => {
    api
      .get("U_INSTCONFBASIC")
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setsimpleMonthData(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Ma'lumotlarni olishni iloji bolmadi, qaytadan urinib ko'ring"
          )
        );
      });
  };

  const getVIPMonth = () => {
    api
      .get("U_INSTCONFVIP")
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setVipMonthData(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Ma'lumotlarni olishni iloji bolmadi, qaytadan urinib ko'ring"
          )
        );
      });
  };

  const searchUser = (a = "") => {
    setCustomer(a);
    api
      .get(
        `BusinessPartners?$select=CardCode,CardName,Address,Phone1,U_Gender,U_PS,BPAddresses&$filter=CardType eq 'cCustomer' and (contains(CardName, '${a}') or contains(U_PS, '${a}') or contains(Phone1, '${a}'))`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setCustomerData(resData);
        if (a.includes(" , ")) {
          setUserCardCode(a.split(" , ")[1]);
        }
      })
      .catch((err) => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate("/login");
        } else {
          errorRef.current?.open(
            get(
              JSON.parse(err.response.data),
              "error.message",
              "Ma'lumotlarni olishni iloji bolmadi, qaytadan urinib ko'ring"
            )
          );
        }
      });
  };

  const searchProduct = (a = "") => {
    setProductName(a);
    api
      .get(
        `Items?$select=ItemCode,ItemName,U_Color,U_Condition&$filter=contains(ItemName,'${a}') and QuantityOnStock gt 0`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setProductNameData(resData);
        if (resData.length < 2) {
          getSameSerialNumnber(a.split(", ")[1]);
          setProductCode(a.split(", ")[1]);
        }
      })
      .catch((err) => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate("/login");
        } else {
          errorRef.current?.open(
            get(
              JSON.parse(err.response.data),
              "error.message",
              "Mahsulotlarni olishni iloji bolmadi, qaytadan urinib ko'ring"
            )
          );
        }
      });
  };

  const getSameSerialNumnber = (num) => {
    api
      .get(`SQLQueries('getItem')/List?itemCode='${num}'`)
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setSeriaNumberData(resData);
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Seria raqamni olishni iloji bolmadi, qaytadan urinib ko'ring"
          )
        );
      });
  };

  const getOtherInformations = (v) => {
    const num = Number(v.split(") ")[0]) - 1;
    setSeriaNumber(v);
    const code = v.split(") ")[1] || "";
    seriaNumberData.forEach((el, index) => {
      if (get(el, "DistNumber", "") === code) {
        setSystemSerialNumber(get(el, "SysNumber", ""));
      }
    });
    setSeriaNumberMain(v.split(") ")[1]);
    const dataO = seriaNumberData[num];

    setColor(get(dataO, "U_Color", ""));
    setCondition(get(dataO, "U_Condition", ""));
    setCurrentPrice(get(dataO, "CostTotal", ""));
  };

  const getOtherInformationsfromSerialNumber = () => {
    api
      .get(
        `SQLQueries('getItemsBySerialNumber')/List?serial='${seriaNumber}%25'`
      )
      .then((res) => {
        const resData = get(JSON.parse(res.data), "value", []);
        setProductNameData(resData);
      })
      .catch((err) => {
        if (JSON.parse(JSON.stringify(err, null, 2)).status === 401) {
          navigate("/login");
        } else {
          errorRef.current?.open(
            get(
              JSON.parse(err.response.data),
              "error.message",
              "Ma'lumotlarni olishni iloji bolmadi, qaytadan urinib ko'ring"
            )
          );
        }
      });
  };

  const createUserF = (consumer, passport, male, address, phone, cardCode) => {
    api
      .post(`BusinessPartners`, {
        CardCode: cardCode,
        CardName: consumer,
        CardType: "cCustomer",
        Phone1: phone,
        U_Gender: male,
        U_PS: passport,
        Currency: "##",
        BPAddresses: [
          {
            AddressName: "Bill To",
            Street: address,
          },
        ],
      })
      .then(() => {
        createUserRef.current?.close();
        succesCreateUserRef.current?.open(
          "Foydalanuvchi muvaffaqiyatli yaratildi"
        );
      })
      .catch((err) => {
        errorRef.current?.open(
          get(
            JSON.parse(err.response.data),
            "error.message",
            "Ma'lumotlarni olishni iloji bolmadi, qaytadan urinib ko'ring"
          )
        );
      });
  };

  const addTable = () => {
    if (
      customer.length > 0 &&
      productName.length > 0 &&
      seriaNumber.length > 0 &&
      (month.length > 0 || monthB2B.length > 0)
    ) {
      let monthNumber = monthB2B.length > 0 ? monthB2B : month;
      monthNumber = Number(monthNumber.split(" Месяц - ")[0]) + 1;

      setTableData([]);

      for (let i = 0; i < monthNumber; i++) {
        let nextMonth = moment().clone().add(i, "months");
        setTableData((pr) => [
          ...pr,
          {
            date: nextMonth.format("YYYY-MM-DD"),
            price: 0,
            price2: 0,
          },
        ]);
      }
      setIsCalculated(true);
    } else {
      infoNotEndedRef.current?.open();
    }
  };

  const ditributeMoney = (money) => {
    let protsent = monthB2B.length > 0 ? monthB2B : month;
    protsent = 1 + Number(protsent.split(" Месяц - ")[1].replace("%", "")) / 100;

    let sameNumber = (Number(currentPrice) - Number(money)) * protsent;

    // console.log("protsent:", protsent, "sameNumber:", sameNumber);
    let monthMoney = sameNumber / (tableData.length - 1);

    setMinusedMoney(Number(money));
    setPrice(sameNumber + Number(money));

    if (Number(money) > 0 && Number(money) < Number(price)) {
      for (let i = 0; i < tableData.length; i++) {
        if (i !== 0) {
          let aa = [...tableData];
          aa[i].price = monthMoney;
          setTableData(aa);
        } else {
          let aa = [...tableData];
          aa[0].price = money;
          setTableData(aa);
        }
      }
    }
  };

  //main create Product

const checkU_WHSCode = () => {
    if (get(getMe, 'U_Warehouse', null) === null) {
      alert('Foydalanuvchiga ombor biriktirilmagan');
    } else {
      createOrder();
    }
  }

  const createOrder = () => {
    const nowDays = new Date();
    let monthNumber = monthB2B.length > 0 ? monthB2B : month;
    monthNumber = Number(monthNumber.split(" Месяц - ")[0]);

    let protsent = monthB2B.length > 0 ? monthB2B : month;
    protsent = 1 + Number(protsent.split(" Месяц - ")[1].replace("%", "")) / 100;

    setIsMainLoading(true);

    const dataT = tableData.map((v) => {
      return {
        Total: v.price,
        DueDate: v.date,
      };
    });

    api
      .post(`Invoices`, {
        CardCode: userCardCode,
        DocDate: time.to(nowDays, "YYYY-MM-DD"),
        DocumentLines: [
          {
            ItemCode: productCode,
            SerialNumbers: [
              {
                InternalSerialNumber: seriaNumberMain,
                SystemSerialNumber: systemSerialNumber,
              },
            ],
            Quantity: 1,
            Price: price,
            WarehouseCode: get(getMe, 'U_Warehouse', '')
          },
        ],
        DocumentInstallments: dataT,
      })
      .then((res) => {
        const resData = JSON.parse(res.data);
        setViewData({
          Invoices: {
            CardName: get(resData, "CardName", ""),
            DocEntry: get(resData, "DocEntry", 0),
            CardCode: get(resData, "CardCode", 0),
          },
          DocumentLines: {
            ItemDescription: productName,
          },
        });
        successModalRef.current?.open("Заказ успешно создан");
      })
      .catch((err) => {
        setIsMainLoading(false);
        errorRef.current?.open(
          get(JSON.parse(err.response.data), "error.message", "")
        );
      });
  };

  const changeMainPrice = (v) => {
    let mainPrice = Number(v);
    setPrice(v);

    let dataT = mainPrice;
    let A = (dataT - minusedMoney) / (Number(tableData.length) - 1);

    for (let i = 0; i < tableData.length; i++) {
      if (i !== 0) {
        let aa = [...tableData];
        aa[i].price = A;
        setTableData(aa);
      } else {
        let aa = [...tableData];
        aa[0].price = minusedMoney;
        setTableData(aa);
      }
    }
  };

  const changeDate = (v) => {
    let exampleDate = [...tableData];
    let day = v.slice(8, 10);

    for (let i = 0; i < exampleDate.length; i++) {
      let nextMonth = moment().clone().add(i, "months").format("YYYY-MM-DD");
      let selectedDay = nextMonth.slice(0, 8) + day;

      if (dateIndex <= i) {
        exampleDate[i].date = selectedDay;
      }
    }
    setTableData(exampleDate);
    changeDateRef.current?.close();
  };

  const openChangeDate = (date, i) => {
    dispatch(setDate(date));
    changeDateRef.current?.open();
    setDateIndex(i);
  };

  return (
    <Layout>
      <CreateProductsStyle>
        <div className='container'>
          <div className='betweenCard'>
            <div className='userSearchCard'>
              <input
                type='text'
                list='client'
                placeholder='Клиент'
                className='input'
                defaultValue={customer}
                onChange={(v) => searchUser(v.target.value)}
              />
              <datalist id='client'>
                {customerData.map((v, i) => (
                  <option
                    key={i}
                    value={`${get(v, "CardName", "")} , ${get(
                      v,
                      "CardCode",
                      ""
                    )}`}
                  />
                ))}
              </datalist>
              <button
                className='createUserBtn'
                onClick={() => createUserRef.current?.open()}
              >
                +
              </button>
            </div>

            <div className='relative'>
              <input
                type='text'
                list='productName'
                placeholder='Товар'
                className='input'
                defaultValue={productName}
                onChange={(v) => searchProduct(v.target.value)}
              />
              <datalist id='productName'>
                {productNameData.map((v, i) => (
                  <option
                    key={i}
                    value={`${get(v, "ItemName", "")} , ${get(
                      v,
                      "ItemCode",
                      ""
                    )}`}
                  />
                ))}
              </datalist>
            </div>

            <div className='userSearchCard'>
              <input
                type='text'
                list='seriaNumber'
                placeholder='Серийный номер'
                className='input'
                defaultValue={seriaNumber}
                onChange={(v) => getOtherInformations(v.target.value)}
              />
              <datalist id='seriaNumber'>
                {seriaNumberData.map((v, i) => (
                  <option
                    key={i}
                    value={`${i + 1}) ${get(v, "DistNumber", "")}`}
                  />
                ))}
              </datalist>
              <button
                className='createUserBtn'
                onClick={getOtherInformationsfromSerialNumber}
              >
                <BsSearch />
              </button>
            </div>

            <input
              type='text'
              placeholder='Цвет'
              className='inputDefault'
              defaultValue={color}
              // onChange={(v) => setColor(v.target.value)}
              disabled={true}
            />
          </div>

          <div className='betweenCard'>
            <input
              type='text'
              placeholder='Состояние'
              className='inputDefault'
              defaultValue={condition}
              disabled={true}
            />

            <input
              type='number'
              placeholder='Текущая цена'
              className='inputDefault'
              defaultValue={currentPrice}
              disabled={true}
            />

            <div className='monthCard'>
              <input
                type='text'
                list='month'
                placeholder='Месяц'
                className='input'
                value={month}
                onChange={(v) => {
                  setMonth(v.target.value);
                  setMonthB2B("");
                }}
              />
              <datalist id='month'>
                {simpleMonthData.map((v, i) => {
                  return (
                    <option
                      key={i}
                      value={`${v.U_Months} Месяц - ${v.U_Percent}%`}
                    />
                  );
                })}
              </datalist>

              <input
                type='text'
                list='monthB2B'
                placeholder='Месяц B2B'
                className='input'
                value={monthB2B}
                onChange={(v) => {
                  setMonthB2B(v.target.value);
                  setMonth("");
                }}
              />
              <datalist id='monthB2B'>
                {vipMonthData.map((v, i) => {
                  return (
                    <option
                      key={i}
                      value={`${v.U_Months} Месяц - ${v.U_Percent}%`}
                    />
                  );
                })}
              </datalist>
            </div>

            <input
              type='number'
              placeholder='Цена'
              className='input'
              value={price}
              onChange={(v) => changeMainPrice(v.target.value)}
            />
          </div>

          <div className='betweenCard'>
            <div className='center'>
              <Button onClick={addTable}>Расчет</Button>
            </div>

            {isCalculated ? (
              <table className='table'>
                <thead>
                  <tr>
                    <th>N</th>
                    <th>Дата</th>
                    <th>Оплата</th>
                    <th>Оплаченный</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{i}</td>
                        <td
                          onClick={() => openChangeDate(v.date, i)}
                          className='mainTd'
                        >
                          {v.date}
                        </td>
                        <td>
                          {i === 0 ? (
                            <input
                              type='text'
                              className='inputStartPrice'
                              onChange={(v) => ditributeMoney(v.target.value)}
                            />
                          ) : (
                            Number(v.price)
                          )}
                        </td>
                        <td>{v.price2}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
          </div>

          <div className='bottomCard'>
            <Button onClick={checkU_WHSCode} isLoading={isMainLoading}>
              Создать
            </Button>
            <Button
              btnStyle={{ marginLeft: 20, backgroundColor: "red" }}
              onClick={() => navigate("/allProducts")}
            >
              Назад
            </Button>
          </div>
        </div>
      </CreateProductsStyle>
      <>
        <CreateUser
          getRef={(r) => {
            createUserRef.current = r;
          }}
          onConfirm={(consumer, passport, male, address, phone, cardCode) =>
            createUserF(consumer, passport, male, address, phone, cardCode)
          }
        />
        <ErrorModal
          getRef={(r) => {
            errorRef.current = r;
          }}
          onConfirm={() => {
            setIsMainLoading(false);
          }}
        />
        <SuccessModal
          getRef={(r) => {
            succesCreateUserRef.current = r;
          }}
        />
        <SuccessModal
          getRef={(r) => {
            successModalRef.current = r;
          }}
          onConfirm={() => navigate("/viewItem", { state: viewData })}
        />

        <ChangeDate
          getRef={(r) => {
            changeDateRef.current = r;
          }}
          onConfirm={(v) => changeDate(v)}
        />

        <WarningModal
          getRef={(ref) => {
            infoNotEndedRef.current = ref;
          }}
        />
      </>
    </Layout>
  );
};

export default CreateProducts;
